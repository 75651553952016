<template>
  <div class="" style="">


    <div id="menu_area" style="" class="menu-area ">
        <div class="margin-nav-m">
            <div class="">
                <nav class="navbar navbar-light navbar-expand-lg mainmenu">
                    <div class="collapse navbar-collapse navDisplayfull " id="navbarSupportedContent" style="margin-right:0px !important">
                      <b-navbar-brand to="/" class="full navDisplayfull"  style="margin-left:30px; margin-right:30px; " >
                        <img src="../assets/NAO/2-home/main-logo-naotravelco.png" height="75" class="ml-auto mr-auto navDisplayfull navbarMode" alt="naotravelco">
                      </b-navbar-brand>  
                       
                      <ul class="navbar-nav navbar-center margin-negative-r">
                          <li class="dropdown">
                              <a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" 
                              aria-haspopup="true" aria-expanded="false">Servicios de viaje</a>
                              <ul class="dropdown-menu ddprimary" aria-labelledby="navbarDropdown">
                                    <li class="dropdown ddprimary" v-for="(item, i) of table_list" :key="i">
                                        <!-- <a v-if="item.id == 1 || item.id == 2 || item.id == 4 || item.id == 7 || item.id == 8" 
                                          class="dropdown-toggle text-white ddprimary" :href="item.identificador" :id="'navbarDropdown'+item.id" role="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >{{item.titulo}}</a> -->
                                        <router-link replace v-if="item.id == 1 || item.id == 2 || item.id == 4 || item.id == 7 || item.id == 8" 
                                          class="dropdown-toggle text-white ddprimary" :to="'/'+item.identificador" :id="'navbarDropdown'+item.id" role="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >{{item.titulo}} </router-link>
                                        <ul class="dropdown-menu ddsecondary" :aria-labelledby="'navbarDropdown'+item.id">
                                          <!-- <li v-for="el of item.subcategorias" :key="el.id"><a class="text-white ddsecondary" :href="'/'+item.identificador+el.id">{{el.titulo}}</a></li> -->
                                          <router-link replace class="text-white ddsecondary" v-for="el of item.subcategorias" :key="el.id"
                                           :to="'/'+item.identificador+'#'+item.identificador+el.id" @click.native="scrollFix(item.identificador+el.id)" >{{el.titulo}}</router-link>
                                        </ul>
                                    </li>
                              </ul>
                          </li>
                          <!-- luxury -->
                          <li class="dropdown">
                              <a class="dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" 
                              aria-haspopup="true" aria-expanded="false">Luxury collection</a>
                              <ul class="dropdown-menu dd" aria-labelledby="navbarDropdown2">
                                  <!-- <li v-for="item of table_list[3].subcategorias" :key="item.id"><a class="text-white dd" :href="'/'+table_list[3].identificador">{{item.titulo}}</a></li> -->
                                  <router-link replace class="text-white ddsecondary" v-for="item of table_list[3].subcategorias" :key="item.id"
                                    :to="'/'+table_list[3].identificador+'#'+table_list[3].identificador+item.id" @click.native="scrollFix(table_list[3].identificador+item.id)" >{{item.titulo}}</router-link>

                              </ul>
                          </li>
                          <!-- nao deals -->
                          <li class="dropdown">
                              <a class="dropdown-toggle" href="/naodeals" id="navbarDropdown3" role="button" data-toggle="dropdown" 
                              aria-haspopup="true" aria-expanded="false">Nao deals</a>
                              <ul class="dropdown-menu dd" aria-labelledby="navbarDropdown3">
                                  <!-- <li v-for="item of table_list[7].subcategorias" :key="item.id"><a class="text-white dd" :href="'/'+table_list[7].identificador">{{item.titulo}}</a></li> -->
                                  <router-link replace class="text-white ddsecondary" v-for="item of table_list[7].subcategorias" :key="item.id"
                                    :to="'/'+table_list[7].identificador+'#'+table_list[7].identificador+item.id" @click.native="scrollFix(table_list[7].identificador+item.id)" >{{item.titulo}}</router-link>

                              </ul>
                          </li>
                          <!-- nao tools -->
                          <li class="dropdown">
                              <a class="dropdown-toggle" href="#" id="navbarDropdown4" role="button" data-toggle="dropdown" 
                              aria-haspopup="true" aria-expanded="false">Nao tools</a>
                              <ul class="dropdown-menu dd" aria-labelledby="navbarDropdown4">
                                  <li><a class="text-white dd" href="#">{{'Tipo de cambio Nao: ' + configuracion[0].tipo_cambio + ' MXN/USD'}}</a></li>
                                  <li><a class="text-white dd pointer" @click="$router.push('/newsletter').catch((err) => {})">NewsLetter</a></li>
                                  <!-- <li v-for="item of table_list[4].subcategorias" :key="item.id"><a class="text-white dd"  :href="'/'+table_list[4].identificador">{{item.titulo}}</a></li> -->
                                  <li v-for="item of table_list[4].subcategorias" :key="item.id"><a class="text-white dd" :href="user.id ? '/admin/NAO_Tools' : '/opps/naotools'">{{item.titulo}}</a></li>

                              </ul>
                          </li>
                          
                          <a class="pointer" href="/login-cruceros" target="_blank" >Cruise Pro</a>
                          <a class="pointer" href="/nao-club" >Nao Club</a>
                          <a class="pointer" href="/nao-pagos" >Nao Pagos</a>
                          <a class="pointer" href="https://naotravelacademy.com/" target="_blank" >Nao Travel Academy</a>
                          

                          
                      </ul>
                      <ul class="navbar-nav right-section">
                        <!-- <li><a href="#">es un button</a></li> -->
                          <router-link replace v-if="user.id" to="/admin/NAO_Quote/0"><span class="btn btn-outline-primary">Mi cuenta</span></router-link>
                          <router-link replace v-if="!user.id" to="/registro"><span class="btn btn-outline-primary">Registrate</span></router-link>
                          <span v-if="user.id" style="margin-top:20px" class="text-center px-2">
                            <img class="mr-2" src="../assets/icon/icon2.png">
                            Hola, {{ user.nombre }}
                          </span>
                          <span style="margin-top:20px; margin-right:30px"  class="text-center pl-2">
                            <img class="mr-3" src="../assets/NAO/22-Vista Producto Logueando/Vector Login.png" alt="">
                            <span @click="logout()" v-if="user.id" class="pointer">Log Out</span>
                            <span @click="login()" v-else  class="pointer">Log in</span>
                          </span>


                      </ul>
                    </div>



            <b-navbar-brand to="/" class="movil" id="logomovil"  style="margin-left:15px; margin-right:15px;" >
              <img src="../assets/NAO/2-home/main-logo-naotravelco.png" height="55"  class="ml-auto mr-auto movil navbarMode" alt="naotravelco">
            </b-navbar-brand>  
            <b-button target="navbarSupportedContent" variant="light" size="lg" class="ml-auto sidebarMode sidebarButton py-1 px-2 media"  style="margin-right:60px !important" v-b-toggle.sidebar-variant><b-icon-justify style="font-size:2.3rem;" /> </b-button>
            <b-button target="navbarSupportedContent" variant="light" size="lg" class="ml-auto sidebarMode sidebarButton py-1 px-2 movil"  style="margin-right:15px !important" v-b-toggle.sidebar-variant><b-icon-justify style="font-size:2.3rem;" /> </b-button>

                    <!-- movil -->
                  <b-sidebar aria-labelledby="sidebar-no-header-title" no-header style="z-index:33333 !important;" class="sidebarMode " id="sidebar-variant"  bg-variant="light" text-variant="dark" shadow >
                    <template #default="{ hide }" class="m-0">                      
                      <div class="row m-0">
                        <div class="col-6 ml-0 pl-0">
                          <a href="/" class="m-2 ml-0 pl-0"><img src="../assets/NAO/2-home/main-logo-naotravelco.png" height="50"  class=" navbarMode" alt="naotravelco"></a>
                        </div>
                        <div class="col-6 mr-0 pr-3">
                          <b-button variant="transparent" class="ml-4 my-4" style="float:right;" @click="hide"><i class="fas fa-times"></i></b-button>
                        </div>
                      </div>
                      
                      
                       <div class="accordion" role="tablist">     
                          <b-button squared @click="serv_viaje=!serv_viaje" block  style="border-radius:none !important;" class="btn btn-white-outline-small py-3" variant="transparent"><b>Servicios De Viaje</b><i class="fas fa-angle-down mx-2"></i></b-button>                          
                          <div v-if="serv_viaje" class="animate__animated animate__fadeIn" >   
                            <div v-for="(item, i) of table_list" :key="i" class="m-0 ">
                              <b-button v-if="item.id == 1 || item.id == 2 || item.id == 4 || item.id == 7 || item.id == 8" squared  
                              block v-b-toggle="'accordionct-' + item.id" style="border-radius:none !important;" class="btn btn-primary" 
                              variant="transparent"><b>{{item.titulo}}</b><i class="fas fa-angle-down mx-2"></i></b-button>                          
                              <b-collapse :id="'accordionct-'+item.id"  :accordion="'accordionct-'+item.id" role="tabpanel">   
                                <div class="p-0 " >
                                  <!-- <b-button v-for="el of item.subcategorias" :key="el.id" squared block style="border-radius:none !important;" class="btn btn-primary-nav m-0" 
                                  variant="transparent"><b>{{ el.titulo}}</b></b-button>   -->
                                  <router-link replace class="btn-block rounded-0 btn btn-primary-nav m-0" v-for="el of item.subcategorias" :key="el.id"
                                  :to="'/'+item.identificador+'#'+item.identificador+el.id" @click.native="scrollFix(item.identificador+el.id)" >{{el.titulo}}</router-link>
                                     
                                </div>                         
                              </b-collapse>  

                            </div>                         
                          </div>   
                                                                       
                          <b-button squared  block v-b-toggle.luxury style="border-radius:none !important;" class="btn btn-white-outline-small mt-0 py-3" variant="transparent"><b>Luxury Collection</b><i class="fas fa-angle-down mx-2"></i></b-button>                          
                          <b-collapse id="luxury"  accordion="luxury" role="tabpanel">   
                            <div class="p-0 " >
                              <router-link replace class="btn-block rounded-0 text-white btn btn-primary m-0" v-for="item of table_list[3].subcategorias" :key="item.id"
                                    :to="'/'+table_list[3].identificador+'#'+table_list[3].identificador+item.id" @click.native="scrollFix(table_list[3].identificador+item.id)" >{{item.titulo}}</router-link>
                              
                                                                                
                            </div>                         
                          </b-collapse>   

                          <b-button squared  block v-b-toggle.deals style="border-radius:none !important;" class="btn btn-white-outline-small mt-0 py-3" variant="transparent"><b>Nao deals</b><i class="fas fa-angle-down mx-2"></i></b-button>                          
                          <b-collapse id="deals"  accordion="deals" role="tabpanel">   
                            <div class="p-0 " >
                              <router-link replace class="btn-block rounded-0 text-white btn btn-primary m-0" v-for="item of table_list[7].subcategorias" :key="item.id"
                                    :to="'/'+table_list[7].identificador+'#'+table_list[7].identificador+item.id" @click.native="scrollFix(table_list[7].identificador+item.id)" >{{item.titulo}}</router-link>
                              
                                                                                
                            </div>                         
                          </b-collapse>   

                          <b-button squared  block v-b-toggle.tools style="border-radius:none !important;" class="btn btn-white-outline-small mt-0 btn-block rounded-0 py-3" variant="transparent"><b>Nao Tools</b><i class="fas fa-angle-down mx-2"></i></b-button>
                          <b-collapse id="tools"  accordion="tools" role="tabpanel">   
                            <div class="p-0 " >
                              <a class="btn-block rounded-0 text-white btn btn-primary m-0 dd" href="#">{{'Tipo de cambio Nao: ' + configuracion[0].tipo_cambio + ' MXN/USD'}}</a>
                              <a class="btn-block rounded-0 text-white btn btn-primary m-0" @click="$router.push('/newsletter').catch((err) => {})">Newsletter</a>
                              <div v-for="item of table_list[4].subcategorias" :key="item.id"><a class="btn-block rounded-0 text-white btn btn-primary m-0 " :href="user.id ? '/admin/NAO_Tools' : '/opps/naotools'">{{item.titulo}}</a></div>
                            </div>                         
                          </b-collapse>    
                           <a class="btn btn-white-outline-small mt-0 btn-block rounded-0 py-3" href="/login-cruceros" target="_blank" ><b>Cruise Pro</b></a>
                           <a class="btn btn-white-outline-small mt-0 btn-block rounded-0 py-3" href="/nao-club" ><b>Nao Club</b></a>
                           <a class="btn btn-white-outline-small mt-0 btn-block rounded-0 py-3" href="/nao-pagos"><b>Nao Pagos</b></a>
                           <a class="btn btn-white-outline-small mt-0 btn-block rounded-0 py-3" href="https://naotravelacademy.com/" target="_blank" ><b>Nao Travel Academy</b></a>

                          <router-link replace v-if="user.id" to="/admin/NAO_Quote/0" class="btn btn-outline-primary mt-0 btn-block rounded-0 py-3"><span >Mi cuenta</span></router-link>
                          <router-link replace v-if="!user.id" to="/registro" class="btn btn-outline-primary mt-0 btn-block rounded-0 py-3"><span >Registrate</span></router-link>    

                          <span class="" style="margin-top:20px">
                            
                            <p @click="logout()" style="float:center;" v-if="user.id" class="pointer text-center btn-block rounded-0 py-3"><img class="text-center mr-2 " src="../assets/NAO/22-Vista Producto Logueando/Vector Login.png" alt="">Log Out</p>
                            <p @click="login()"  style="float:center;" v-else  class="btn btn-light pointer text-center btn-block rounded-0 py-3"><img class="text-center mr-2" src="../assets/NAO/22-Vista Producto Logueando/Vector Login.png" alt="">Log in</p>
                          </span>
                      </div>
                    </template>
                    
                  </b-sidebar>


                </nav>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import Vue from 'vue';
import VueCascaderSelect from 'vue-cascader-select';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery'; 

import store from "../store/index";
export default {
  
  data: () => ({
    serv_viaje: false,
    columncategories: [],
    categories: [],
    showCategories: false,
    cartItems: 0,
    user: false,
    storeId: null,
    options:[
      {
        label: 'Cruceros',
        value: 'v1',
        disabled: false,
        options: [
          { label: 'Ultra lujo', value: 'c1' },
          { label: 'Lujo', value: 'c2' },
          { label: 'Contemponáneas', value: 'c3' },
          { label: 'De expedición y río', value: 'c4' },
        ],
      },
      {
        label: 'Circuitos',
        value: 'cc1',
        disabled: false,
        options: [
          { label: 'Europa', value: 'cc2' },
          { label: 'Asia', value: 'cc3' },
          { label: 'Canadá', value: 'cc4' },
          { label: 'Centro y Sudamérica', value: 'cc5' },
          { label: 'México', value: 'cc6' },
          { label: 'África', value: 'cc7' },
        ],
      },
      {
        label: 'Hoteles',
        value: 'd1',
        disabled: false,
      },
      {
        label: 'Parques',
        value: 'e1',
        disabled: false,
      },
      {
        label: 'Otros servicios',
        value: 'f1',
        disabled: false,
        options: [
          { label: 'Renta de autos', value: 'f2' },
          { label: 'Traslados', value: 'f3' },
          { label: 'Tours y excursiones', value: 'f4' },
          { label: 'Seguros', value: 'f5' },
        ],
      },
    ],
    options2:[
      {
        label: 'Tipo de cambio Nao: 19.56 MXN/USD',
        value: 'v1',
        disabled: false,
      },
      { label: 'Beneficios', value: 'c2' },
      { label: 'Catálogos', value: 'c3' },
      { label: 'Información útil para el viajero', value: 'c4' },
    ],
    value0: null,
    value1: null,
    table_list: [],
    configuracion:[{tipo_cambio:''}]

  }),
  components: {
    VueCascaderSelect,
  },
  watch: {},
  async mounted() {
    // new navbar 20/03/22 23:32

    // $(document).on('click', '.dropdown-menu', function (e) {
    //   e.stopPropagation();
    // });

    // // make it as accordion for smaller screens
    // if ($(window).width() < 992) {
    //   $('.dropdown-menu a').click(function(e){
    //     e.preventDefault();
    //       if($(this).next('.submenu').length){
    //         $(this).next('.submenu').toggle();
    //       }
    //       $('.dropdown').on('hide.bs.dropdown', function () {
    //     $(this).find('.submenu').hide();
    //   })
    //   });
    // }
    this.$store.watch(
      (state) => [state.user, state.refreshCartKey, state.refreshLogoutButtonKey].join(),
      () => {
        this.updateStatus()
        this.showItemsNumber();
        this.refreshButton();
      }
    );

    this.user = JSON.parse(localStorage.getItem("user")) || false;
    await this.getConfiguracion()
    // this.categories = await store.dispatch("getAll", "categories");
    
    // this.showItemsNumber();
    // const nCols = 4;
    // const nItems = this.categories.length / nCols;
    // const remaining = this.categories.length % nCols;

    // let columnValues = [];
    // for (let i = 1; i <= nCols; i++) {
    //   let n = Math.floor(nItems);
    //   if (remaining < nCols - i) {
    //     n += 1;
    //   }
    //   //he agregado esta condición porque daba error
    //   if (i <= nItems) columnValues.push(n);
    // }

    // let n = 0;
    // for (let i = 0; i < columnValues.length; i++) {
    //   const value = columnValues[i];
    //   let column = [];
    //   for (let ii = 0; ii < value; ii++) {
    //     const category = this.categories[n];
    //     //he agregado esta condición porque daba error
    //     if (this.categories[n]) {
    //       column.push(category);
    //     }
    //     n++;
    //   }
    //   this.columncategories.push(column);
    // }
    await this.getData()
  },
  methods: {
    async getConfiguracion() {
      try {
        let res = await this.$store.dispatch("get", { path: 'configuracion/getAll'});
        console.log(res)
        if(res.length>0){
          this.configuracion = res
        }
        else{
          this.configuracion = []
        }
      } catch (error) {
        this.configuracion = []
      }
    },      
    scrollFix(item){
      this.$store.commit('refreshScrollKey', item)
    },
    async getData() {
      try {
        this.$isLoading(true)
        let res = await this.$store.dispatch("get", { path: 'categorias/getAllWithSubcategory/'});
        this.$isLoading(false)        
        //console.log(res)
        if(res.length>0){
          this.table_list = res
          //console.log('zz--->',this.table_list)
        }
        else{
          this.table_list = []
        }
      } catch (error) {
        this.$isLoading(false)        
        this.table_list = []
      }
    },    
    login() {
      this.$router.push('/login_new/navbar')
      // window.location.reload();
    },    
    logout() {
      this.$store.commit('logout')
    },    
    updateStatus(){
      this.user = localStorage.getItem('user') || {}
    },
    goto(e){
      //console.log(e)
      let value = e.value
      if(value === 'c1' || value === 'c2' || value === 'c3' || value === 'c4'){
        //console.log(e.value)
        this.$router.push('/cruceros')
      }
      if(value === 'cc2' || value === 'cc3' || value === 'cc4' || value === 'cc5' || value === 'cc6' || value === 'cc7'){
        //console.log(e.value)
        this.$router.push('/circuitos')
      }
      if(value === 'e1'){
        //console.log(e.value)
        this.$router.push('/parques')
      }
      if(value === 'd1'){
        //console.log(e.value)
        this.$router.push('/hotel')
      }
      if(value === 'f2' || value === 'f3' || value === 'f4' || value === 'f5'){
        //console.log(e.value)
        this.$router.push('/otros')
      }
    },
    showModalStores() {
      //this.$emit("showModal");
      localStorage.removeItem("storeId");
      this.$router.replace("/");
      window.location.reload();
    },
    gotoStore(category) {
      this.$router.replace("/store/" + category);
    },
    async showItemsNumber() {
      this.storeId = JSON.parse(localStorage.getItem("storeId"));
      const cartItems =
        JSON.parse(localStorage.getItem("productDetail-" + this.storeId)) || [];
      if (cartItems) {
        this.cartItems = cartItems.length;
      } else {
        this.cartItems = 0;
      }
    },
    refreshButton() {
      this.user = JSON.parse(localStorage.getItem("user")) || false;
    },
    checkStatusUser(type) {
      const user = JSON.parse(localStorage.getItem("user"));
      let userId;
      user ? (userId = user.id) : (userId = 0);
      if (userId > 0) {
        if (type == 1) this.$router.push("/Customers").catch((err) => {});
        if (type == 2) this.$router.push("/CheckOut").catch((err) => {});
      } else {
        this.$router.push("/login").catch((err) => {});
      }
    },
  },
  ready: function() {
      $('.dropdown-submenu a.test').on("click", function(e){
        $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
      });    
    }
};
</script>

<style lang="scss" scoped>


.margin-negative-r{

}
.navDisplaymedia {
    display: none !important;
    // visibility:hidden !important;
  }
.margin-nav-m{
    
  }
.div-flex{
  background: transparent;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-flex > div{
  text-align: center;
}
.media{
  display: none !important;
}
.movil{
  display: none !important;
}
.margin-nav-l{
  margin-left: 60px;
}
.margin-nav-r{
  margin-left: 60px;
}
  .navDisplay-flex-full{
    visibility: visible !important;
  }
  .navDisplayfull{
    display: block;
  }
  
  img.navDisplayfull{
    max-width: 210px;
  }
 
  .right-section{
    max-width: 300px;
  }
  
  .margin-nav{
    left:50% !important; 
    right:50% !important;
  }
  .nav-margin{
    padding-left: 90px !important;
  }

  .column-menu {
    @extend .slide-bottom;
    top: 50px;
    position: absolute;
    border-radius: 4px;
  }

  .column-menu li {
    list-style: none;
    padding: 4px;
  }

  .navbar {
    z-index: 33;
    
  }

  .nav-link {
    color: rgba(0, 0, 0, 0.7) !important;
    margin: 10px;
    font-size: 16px;
    padding: 0.5rem 1rem;
    padding-left: 0% !important;
    padding-right: 0% !important;
    
  }

  .slide-bottom {
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}


.sidebarMode {
  visibility: hidden !important;
}

@media only screen and (max-width: 1000px) {
  

  
}

  .vcs__picker input,
  .vcs__select-menu {
    background: #282b34 !important;
    color: white !important;
    border-color: #282b34 !important;
  }

  .vcs__picker input::placeholder {
    color: #bbb !important;
  }

  .vcs__option--active {
    background: #41444e !important;
  }

  .vcs__option:hover {
    background: #474b56 !important;
  }
  .vcs__arrow-container{
      border-left: 1px solid rgb(126, 29, 29) !important;
      cursor: pointer;
      padding-left: 0px;
      margin-left: 0px;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translate(0,-50%);
  }

  .imgVisible{
    display: none;
  }

  .sidebarButton{
      display: none;
    }
  .full2{
    /* display:inline-block; */
    visibility:visible !important;
  }
  .navDisplaymedia {
    display:none !important;
    // visibility:hidden !important;
  }
/* @media screen and (max-width: 600px) { */
@media screen and (max-width: 1350px) and (min-width: 720px) { 
  .margin-negative-r{
    margin-left: -25px;
  }
  }
@media screen and (max-width: 1555px) and (min-width: 720px) { 
  .margin-mq{
    margin-top:15px
    }
  .div-flex{
    background: transparent;
    height: 11vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .div-flex > div{
    text-align: center;
  }
}
@media screen and (max-width: 1540px) and (min-width: 1500px) {  
  // .nav-margin {
  //   padding-left: 80% !important;
  // }
  .margin-nav{
    left:0% !important; 
    right:0% !important;
  }
}
@media screen and (min-width: 720px) {
  #logomovil {
    display: none !important;
  }
}
@media screen and (max-width: 1200px) and (min-width: 720px) {  
  .margin-nav-m{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .media{
    display: block !important;
  }
  .movil{
    display: none !important;
  }
  .sidebarButton{
    display: block;
  }
  .imgVisible{
    display: block;
  }
  .navDisplaymedia {
    display: flex !important;
    
    text-align: left !important;
  }
  .navDisplay-flex-full{
    visibility: hidden !important;
  }
  .navDisplayfull {
    display: none !important;
  }
  .sidebarMode {
    visibility: visible !important;
  }
  
  .full2{
    display: none !important;
    visibility:hidden !important;
  }
}

a{
  text-decoration: none !important;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: space-between;
}

@media screen and (max-width: 1200px) and (min-width: 992px){
    .navbar-expand-lg .navbar-collapse {
      display: none !important;
      flex-basis: auto;
    }
}
@media only screen and (max-width: 720px) {
  .margin-nav-m{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .media{
    display: none !important;
  }
  .movil{
    display: block !important;
  }
  .margin-nav-l{
    margin-left: 16px;
  }
  .margin-nav-r{
    margin-left: 16px;
  }
  .sidebarButton{
    display: block;
  }
  .imgVisible{
    display: block;
  }
  .navDisplaymedia {
    visibility:none !important;
    display: none !important;
  }
  .navDisplay-flex-full{
    visibility: hidden !important;
  }
  .navDisplayfull {
    display: none !important;
  }
  .sidebarMode {
    visibility: visible !important;
  }
}

//////////////////////////////////
.menu-area{background: #ffffff}
.dropdown-menu{color:white !important; padding:0;margin:0;border:0 solid black transition!important;border:0 solid rgba(0,0,0,.15);border-radius:0;-webkit-box-shadow:none!important;box-shadow:none!important}
.mainmenu a, .navbar-default .navbar-nav > li > a, .mainmenu ul li a , .navbar-expand-lg .navbar-nav .nav-link{color:#fff;font-size:16px;font-family:'Roboto',sans-serif;display: block !important;padding:8px 6px; vertical-align: center;}
.mainmenu .active a,.mainmenu .active a:focus,.mainmenu .active a:hover,.mainmenu li a:hover,.mainmenu li a:focus ,.navbar-default .navbar-nav>.show>a, .navbar-default .navbar-nav>.show>a:focus, .navbar-default .navbar-nav>.show>a:hover{}//color: #fff;background: #000000;outline: 0;}
/*==========Sub Menu=v==========*/
.mainmenu .collapse ul > li:hover > a{} //background: #000000;color:white !important}
.mainmenu .collapse ul ul > li:hover > a, .navbar-default .navbar-nav .show .dropdown-menu > li > a:focus, .navbar-default .navbar-nav .show .dropdown-menu > li > a:hover{background: #303030;}
.mainmenu .collapse ul ul ul > li:hover > a{background: #303030; color:white !important}

.mainmenu .collapse ul ul, .mainmenu .collapse ul ul.dropdown-menu{background:black;}
.mainmenu .collapse ul ul ul, .mainmenu .collapse ul ul ul.dropdown-menu{background:black}
.mainmenu .collapse ul ul ul ul, .mainmenu .collapse ul ul ul ul.dropdown-menu{background:black}

/******************************Drop-down menu work on hover**********************************/
.mainmenu{background: none;border: 0 solid;margin: 0;padding: 0;min-height:20px;width: 100%;}
@media only screen and (min-width: 767px) {
.mainmenu .collapse ul li:hover> ul{display:block}
.mainmenu .collapse ul ul{position:absolute;top:100%;left:0;min-width:250px;display:none}
/*******/
.mainmenu .collapse ul ul li{position:relative}
.mainmenu .collapse ul ul li:hover> ul{display:block}
.mainmenu .collapse ul ul ul{position:absolute;top:0;left:100%;min-width:250px;display:none}
/*******/
.mainmenu .collapse ul ul ul li{position:relative}
.mainmenu .collapse ul ul ul li:hover ul{display:block}
.mainmenu .collapse ul ul ul ul{position:absolute;top:0;left:-100%;min-width:250px;display:none;z-index:1}

}
@media only screen and (max-width: 767px) {
.navbar-nav .show .dropdown-menu .dropdown-menu > li > a{padding:16px 15px 16px 35px}
.navbar-nav .show .dropdown-menu .dropdown-menu .dropdown-menu > li > a{padding:16px 15px 16px 45px}
}

.text-white{
  color:white !important
}

.navbar-nav > .dropdown, .navbar-nav > a {
  display: flex !important;
  align-items: center;
}
// .navbar-nav.navbar-center {
//     position: absolute;
//     left: 50%;
//     transform: translatex(-50%);
// }
.ddprimary{
  border-radius:  10px 0px 0px 10px;
  text-align: center;
}
.ddsecondary{
  border-radius:  0px 10px 10px 0px;
  text-align: center;
}
.dd{
  border-radius:  10px 10px 10px 10px;
  text-align: center;
}

</style>