<template>
    <div class="col-sm-12 col-md-8 col-lg-8 px-md-5">
        <div class="mt-5">
          <form class="px-md-3" @submit.prevent="sendMessage()">
            <div class="form-group my-3 card_full">
                <div class="row">
                  <div class="col-md-4 p-0 pl-3 mr-0" data-aos-duration="950" data-aos="fade-up">
                    <input v-model="mensaje.nombre" required type="text" class="form-control mb-3" id="exampleInputPassword1" placeholder="Nombre">
                  </div>
                  <div class="col-md-4 p-0 pl-3 mr-0" data-aos-duration="1000" data-aos="fade-up">
                    <input v-model="mensaje.email" required type="email" class="form-control mb-3" id="exampleInputPassword1" placeholder="Email">
                  </div>
                  <div class="col-md-4 p-0 pl-3 mr-0" data-aos-duration="1050" data-aos="fade-up">
                    <input v-model="mensaje.telefono" required type="text" class="form-control mb-3" id="exampleInputPassword1" placeholder="Teléfono">
                  </div>
                  <div class="col-12 p-0 pl-3 mr-0" data-aos-duration="1100" data-aos="fade-up">
                    <div class="form-group">
                      <textarea v-model="mensaje.mensaje" required class="form-control" cols="100" style="height:12rem;" placeholder="Tu mensaje" id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                  </div>
                </div>
            <button data-aos="fade-up" type="submit" class="btn btn-primary">Enviar</button>
            </div>
          </form>
              

          <form class="px-md-3" @submit.prevent="sendMessage()">
            <div class="form-group row my-3 card_movil">
              <div class="col-sm-12 col-md-4 p-0 mr-0">
                <input v-model="mensaje.nombre" required type="text" class="form-control mb-3" id="inputname1" placeholder="Nombre">
              </div>
              <div class="col-sm-12 col-md-4 p-0 mr-0">
                <input v-model="mensaje.email" required type="email" class="form-control mb-3" id="inputemail1" placeholder="Email">
              </div>
              <div class="col-sm-12 col-md-4 p-0 mr-0">
                <input v-model="mensaje.telefono" required type="text"  class="form-control mb-3" style="margin-left:0px !important" id="inputphone1" placeholder="Teléfono">
              </div>
                <div class="col-12 p-0 pl-md-3 mr-0">
                  <div class="form-group">
                    <textarea v-model="mensaje.mensaje" required class="form-control" style="height:12rem; " placeholder="Tu mensaje" id="exampleFormControlTextarea11" rows="3"></textarea>
                  </div>
                </div>
            <button type="submit" data-aos="fade-up" class="btn btn-primary">Enviar</button>
            </div>
          </form>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
          mensaje:{}

        }
    },mounted(){

    },
    methods:{
        async sendMessage(){
        console.log(this.mensaje)
        try {
            this.mensaje.fecha = this.$moment().format('YYYY-MM-DD hh:mm:ss')
            let req = await this.$store.dispatch("post", {
            path: 'contacto/new',
            data: this.mensaje
            });
            let contacto = {
              "nombre":this.mensaje.nombre,
              "email":  this.mensaje.email,
              "agencia": "",
              "mensaje": this.mensaje.mensaje
            }
            req = await this.$store.dispatch("post", {
            path: '/enviarCorreo/contacto',
            data: contacto
            });

            this.$swal({
            icon: "success",
            title: "Muy bien!",
            text: "¡Gracias por su mensaje! En breve será contactado.",
            });
            this.mensaje={}
        } catch (error) {
            console.log(error)
            this.$swal({
            icon: "error",
            title: "Oopss!",
            text: "Error, revisar.",
            });        
            
        }

        },
    }

}
</script>

<style>

</style>