import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url: "https://api.naotravelco.com/api/",
    token: null,
    user: null,
    showEditItemsModal: false,
    scrollKey: 0,
    ancla:''
  },
  mutations: {
    logout(state, payload){
      state.user = null;
      state.token = null;
      localStorage.removeItem('user')
      localStorage.removeItem('tk')

      router.push('/');
      window.location.reload(); // forzar recarga de header
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user))
    },    
    setToken(state, token) {
      localStorage.setItem('tk', token)   
      state.token = token;
    },
    refreshShowEditItemsModal(state, payload){
      state.showEditItemsModal = payload
    },
    refreshScrollKey(state, payload){
      console.log('actualizando scroll')
      state.scrollKey++
      state.ancla = payload
    }
  },
  actions: {
    async onFileSelected(context, {filename, file}) {
      try {
        //const file = event.target.files[0];
        // let filename = Math.floor(Math.random() * 9999999999999 + 1);
        // filename = name+'_'+filename+ '.jpg';
        // console.log(filename)
        console.log('subiendo archivo...')
        let formData = new FormData();
        formData.append("name", "my-picture");
        formData.append("file", file);
        let url = context.state.url  + 'subirarchivos2/'+filename;
        let token = localStorage.getItem('tk');

        let opts = {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}` // Ajusta según dónde almacenes el token
          },
        };
        let req = await fetch(url, opts);

        if (req.status === 403) {
          this.commit('logout');
          throw Error('403 - Unauthorized');
        }

        if (req.ok) {
          // this.$swal.fire({
          //   title: "Archivo subido correctamente!",
          //   icon: "success",
          // });
          return true
        }
      } catch (error) {
        console.log("error in onfileselected", error);
        return false
      }
    },
    async login(context, { data }) {
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        };
        let req = await fetch(context.state.url + "usuarios/authenticate", options );
        let res =  await req.json();
        context.state.token = res.token
        localStorage.setItem('tk', res.token)
        localStorage.setItem('user',JSON.stringify(res.data))
        if(res.error){
          console.log('respuesta ', res)
          //this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
          //alert(res.error)
          return res;
        }
        else{
          //console.log('login')
          return res;
        }
      } 
      catch (error) {
        console.error('error', error)
        return error;
      }
    },   
    
    async get(context, { path }) {
      try {
        let token = localStorage.getItem('tk');
        const options = {
          method: "GET",
          headers: {
            'access-token': token,
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        };
        let req = await fetch(context.state.url + path, options );
        let res =  await req.json();

        if (req.status === 403) {
          this.commit('logout');
          throw Error('403 - Unauthorized');
        }

        if(res.error){
          console.error(res.error)
          alert(res.mensaje)
        }
        else{
          return res
        }
      } catch (error) {
        console.error('error', error)
        return error;
      }
    },
    async post(context, { path, data }) {
      try {
        let token = localStorage.getItem('tk');
        const options = {
            method: "POST",
            headers: {
                'access-token': token,
                'Authorization': token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };
        let req = await fetch(context.state.url + path, options );
        let res =  await req.json();
        if (req.status === 403) {
          this.commit('logout');
          throw Error('403 - Unauthorized');
        }
        if(res.error){
          alert(res.error)
        }
        else{
          return res
        }
      } catch (error) {
        console.error('error---------->', error)
        return error;
      }      
    },
    async put(context, { path, data }) {
      try {
        let token = localStorage.getItem('tk');
        const options = {
          method: "PUT",
          headers: {
            'access-token': token,
            'Authorization': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        let req = await fetch(context.state.url + path, options );
        let res =  await req.json();
        if (req.status === 403) {
          this.commit('logout');
          throw Error('403 - Unauthorized');
        }
        if(res.error){
          alert(res.error)
        }
        else{
          return res
        }
      } catch (error) {
        console.error('error---------->', error)
        return error;
      }      
    },
    async delete(context, { path }) {
      try {
        let token = localStorage.getItem('tk');
        const options = {
          method: "DELETE",
          headers: {
            'access-token': token,
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        };
        let req = await fetch(context.state.url + path, options);
        //console.log(req)
        let res =  await req.json();
        if (req.status === 403) {
          this.commit('logout');
          throw Error('403 - Unauthorized');
        }
        if(req.status!= 200){
          alert(req.error)
        }
        else{
          return req
        }
      } catch (error) {
        console.error('error---------->', error)
        return error;
      }      
    },

    async downloadClientesExcel(context) {
      try {
        let token = localStorage.getItem('tk');
        const options = {
          method: "GET",
          headers: {
            "Authorization": token
          }
        };

        const response = await fetch(context.state.url + "clientes/getExcel", options);

        if (!response.ok) throw new Error("Error al descargar el archivo");

        // Convertir la respuesta a un Blob
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Crear un enlace temporal y simular el click
        const a = document.createElement("a");
        a.href = url;
        a.download = "clientes.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Liberar memoria
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error al descargar el archivo:", error);
      }
    }
    
  },
  modules: {
  }
})
