<template>
  <div class="">
    <div class="contenedor pointer" @click="selectStore(item)" v-for="item of stores" :key="item.id">
      <!-- <img class="img" :src="item.picture === null ? '' : require(`@/assets/${item.picture}`)" /> -->
      <img class="img" :src="item.picture" />
      <div class="centrado">{{ item.name }}</div>
    </div>

    <h5 class="text-center primary">REALIZAMOS ENVIOS A TODO EL PAIS</h5>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      stores: [],
    };
  },
  async mounted() {
    await this.getStores();
  },
  methods: {
    selectStore(item) {
      localStorage.setItem("storeId", item.id);
      this.$store.commit("refreshListProducts");
      this.$store.commit("refreshCart");
      this.$emit("close");
    },
    async getStores() {
      try {
        this.stores = await this.$store.dispatch("getAll", "stores");
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>

<style>
.circular--square {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px;
  margin: 0 0.4em;
  padding: 0 !important;
  border: 3px solid #fd306e;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.9);
}

.divimg {
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 5px;
  color: white;
  border-radius: 20px;
}

/* /////////////////////////////// */
.img {
  filter: brightness(65%);
  width: 200px;
  height: 200px;
  margin: 15px;
  border-radius: 10px;
}
.contenedor {
  position: relative;
  display: inline-block;
  text-align: center;
}
.texto-encima {
  position: absolute;
  top: 10px;
  left: 10px;
}
.centrado {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
}
</style>