<template>
<div>
  <!-- <div class="container-fluid bg-gray pb-5">
      <div class="row pb-4">
          <div class="col-md-4 py-5 pl-5 mt-5 full">
            <div class="ml-3">
              <h1 class=" text-left" data-aos="fade-down">{{itemCategoria.titulo_principal}}</h1>
              <p v-html="itemCategoria.descripcion_principal" class="py-4" data-aos="fade-up" />
              <button @click="goto(itemCategoria.ruta_boton_principal)" class="btn btn-primary" data-aos="fade-up">{{itemCategoria.texto_boton_principal}} <i class="px-2 fas fa-chevron-down"></i> </button>
            </div>
          </div>

          <carousel :datos="card_img2" />

          <div class="col-md-4 py-md-5 pl-md-5 mt-5 movil">
            <div class="ml-md-3">
              <h1 class=" text-left" >Nuestras mejores <br> ofertas</h1>
              <p class="py-4">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
              <button class="btn btn-primary">Ver todo <i class="px-2 fas fa-chevron-down"></i> </button>
            </div>
          </div>
      </div>
  </div> -->

  <!-- categorias -->

  <!-- products -->

  <div class="container-fluid p-0 padre-flex" :style="'background-image: url('+ '../img/hero-parques.jpg' +');background-color: #cccccc;height: 500px;background-position: center; background-repeat: no-repeat; background-size: cover; position: relative;'" >

    <div style="padding:10%;">
        <h1 class="text-light pt-5 pt-md-0" data-aos="zoom-in">{{ "Parques" }}</h1>
        <h4 class="text-light pt-5 mt-4 mb-4 pt-md-0" data-aos="zoom-in">{{ "Reserva tickets y hoteles en Walt Disney World® Resort, Disneyland® y Universal Orlando® Resort. Aquí también encontrarás tickets para otros parques en Florida. "}}</h4>
        <div @click="goParks()" class="text-center pt-2 pt-md-0" data-aos="zoom-in"><button class="btn btn-primary ">{{ "Reserva aquí" }}</button></div>
    </div>
  </div>

  <div v-for="item of items[0].subcategorias" :key="item.id" class="container-fluid my-5 px-md-5">
    <div class=" mt-2 mb-2 mx-md-4">
      <div class="title " data-aos="fade-down">
        {{item.titulo}}
      </div>
      <!-- <p :id="'hotel'+item.id" class="" data-aos="fade-up" data-aos-duration="500">	Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p> -->
      <p :id="'parques'+item.id" class="" data-aos="fade-up" data-aos-duration="500">{{item.descripcion}}</p>

    </div>
    <div class="inner only-this-horizon-scrollbar" style="">
      <div class="scroll-container diff-size " >
        <div class="scroll-section " v-for="el of item.tarjetas" :key="el.id">
            <product-vue :product="el" class="zoom" />
        </div>
      </div>
    </div>

  </div>

  <!-- otras recomendaciones -->
  <div class="container-fluid px-md-5 pb-5">
    <div class=" mt-2 mb-2 mx-md-4 mx-md-4 mx-2">
      <div class="title " data-aos="fade-down">
        <span><b> Otras recomendaciones</b></span>
      </div>
      <p class="" data-aos="fade-up" data-aos-duration="500">	Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
    </div>
    <div class="row px-md-3 card_full"  >
      <card-img :class="'col-'+ item.columnas" v-for="item of categorias"
      :key="item.id"
      :product="item" />
    </div>

    <div class="row px-3 card_movil"  >
      <card-img :class="'px-0 col-12'" v-for="item in categorias"
      :key="item.id"
      :product="item" />
    </div>
  </div>

   <!-- tienes alguna duda -->
  <div class="container-fluid bg-white my-5">
      <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4  px-md-5">
        <div class="mt-5 mb-2 mx-md-4">
          <div class="title " data-aos="fade-right" >
            Contáctanos
          </div>
          <p class="mt-3" data-aos="fade-right">¡Para planear juntos el viaje ideal de tu cliente!</p>
        </div>
        <div class="mx-md-2">
          <p data-aos="fade-right" data-aos-duration="950"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (1).png" alt=""><a class="text-black" href="tel:(55)66513626">(55) 66 51 36 26</a></p>
          <p data-aos="fade-right" data-aos-duration="950"><i class="fab h4 fa-whatsapp mx-3"></i><a class="text-black" href="https://api.whatsapp.com/send?phone=525513608624">+ 52 55 13 60 86 24</a></p>
          <!-- <p data-aos="fade-right" data-aos-duration="1000"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (2).png" alt=""><a class="text-black" target="_blank" href="https://api.whatsapp.com/send?phone=+525513608649">+ 52 55 13 60 86 49</a></p> -->
          <!-- <p data-aos="fade-right" data-aos-duration="1050"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (3).png" alt=""><a class="text-black text-decoration:none" href="mailTo:ventas@naotravelco.com">ventas@naotravelco.com</a></p> -->
          <p data-aos="fade-right" data-aos-duration="1050"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (3).png" alt=""><a class="text-black text-decoration:none" href="mailTo:reservas@naotravelco.com">reservas@naotravelco.com</a></p>

        </div>
      </div>

        <contact />

      </div>
    <form action="https://www.reservasparquestematicos.com" method="POST" target="_self" style="height:1px;overflow:hidden;">
    <input type="hidden" name="agentID" value="" id="agent-id"><br>
    <input type="submit" value="Submit" id="send-form">
  </form>

    </div>
  <!-- fin -->


</div>
</template>

<script>
import carousel from "../components/carousel.vue";
import ProductVue from "../components/Product.vue";
import CardImg from "../components/Card-img.vue";
import ProductDetailVue from "../components/ProductDetail.vue";
import contact from '../components/Contact.vue'


export default {
  components: { ProductVue, ProductDetailVue, carousel, CardImg, contact },
  data() {
    return {
      slide: 0,
      sliding: null,
      categories: [],
      productData: {},
      storeId: 0,
      productSearch: null,

      products: [],

      offerProducts: [],
      card_crucero:[
        {id:1,
       titulo: "Crucero por el mar mediterraneo",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        img: '../img/card_.png',
        textoboton: 'Ver detalles'
        },
        {id:2,
        titulo: "Crucero por el mar mediterraneo",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        img: '../img/card_.png',
        textoboton: 'Ver detalles'
        },
        {id:3,
        titulo: "Crucero por el mar mediterraneo",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        img: '../img/card_.png',
        textoboton: 'Ver detalles'
        },
        {id:4,
        titulo: "Crucero por el mar mediterraneo",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        img: '../img/card_.png',
        textoboton: 'Ver detalles'
        },
        {id:5,
        titulo: "Crucero por el mar mediterraneo",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        img: '../img/card_.png',
        textoboton: 'Ver detalles'
        },
        {id:6,
        titulo: "Crucero por el mar mediterraneo",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        img: '../img/card_.png',
        textoboton: 'Ver detalles'
        },
        {id:7,
        titulo: "Circuitos",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        img: '../img/card_.png',
        textoboton: 'Ver detalles'
        },
        {id:8,
        titulo: "Crucero por el mar mediterraneo",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        img: '../img/card_.png',
        textoboton: 'Ver detalles'
        },
        {id:9,
        titulo: "Crucero por el mar mediterraneo",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        img: '../img/card_.png',
        textoboton: 'Ver detalles'
        },
        {id:10,
        titulo: "Crucero por el mar mediterraneo",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        img: '../img/card_.png',
        textoboton: 'Ver detalles'
        },


      ],
       card_img:[
        {id: 1,
        titulo: "Cruceros",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        textoboton: "Ver mas",
        columnas: 12,
        img: '../img/Banner_2.png',
        posicionpanel: 'arriba',
        columnaspanel:6,
        width: '480',
        height: '370',
        // alturapanel: 200,
         ruta: '/cruceros',
        panelhorizontal: 0,
        izquierda: 6,
        },
        {id:2,
        titulo: "Circuitos",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        textoboton: "Ver mas",
        columnas: 7 ,
        img: '../img/Banner.png',
        posicionpanel: 370,
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 0,
        alturapanel: 240,
        ruta: '/circuitos',
        },

        {id: 3,
        col_text: 7,
        col_button: 4,
        titulo: "Hoteles",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        textoboton: "Ver mas",
        columnas: 5,
        img: '../img/Banner_1.png',
        posicionpanel: 'arriba',
        columnaspanel:12,
        width: '480',
        height: '370',
        // alturapanel: 210,
        panelhorizontal: 1,
        ruta: '/hotel',
        },
        {id: 4,
        titulo: "Luxury Collection",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        textoboton: "Ver mas",
        columnas: 5,
        img: '../img/banner4.png',
        posicionpanel: 'arriba',
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 240,
        posicionpanel: 370,
        panelhorizontal: 1,
        col_text: 7,
        col_button: 4,
        ruta: '/luxury',
        },
        {id:5,
        titulo: "Promociones y recursos para agentes de viaje",
        descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.",
        textoboton: "Ver mas",
        columnas: 7 ,
        img: '../img/Banner_3.png',
        posicionpanel: 370,
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 2,
        ruta: '/',
        alturapanel: 200,

        },


      ],
      card_img2:[
        {id:1,
        titulo: "Crucero por el caribe",
        descripcion:"Desde 1,400 USD",
        textoboton: "Ver mas",
        columnas: 12 ,
        img: '../img/Banner2.png',
        posicionpanel: 370,
        columnaspanel:7 ,
        panelhorizontal:1,
        width: '930',
        height: '370',
        izquierda: 0,
        alturapanel: 110,
        col_text: 7,
        col_button: 4,

        },
      ],
      /////////
      categoria_id: 7,
      table: 'categorias',
      items: [],
      rutaCategoria: 'parques',
      categorias:[],
      itemCategoria:{},

      //useremail:'',
    };
  },
  async mounted() {
    this.$store.watch(
      (state) => [state.scrollKey].join(),
      () => {
        this.scrollFix(this.$store.state.ancla)
      }
    );
    this.$isLoading(true)
    await this.getData()
    await this.getCarousel()
    await this.getCategorias()
    this.$isLoading(false)

  },
  methods: {
    goParks(){
      // first theck if the user is logged in
      let userdata = JSON.parse(localStorage.getItem('user')) || null;
      if (userdata){ // the user is logged in
        if (userdata.estado){ // also theck if the user is active (not null)
          document.getElementById("agent-id").value = userdata.email;
          document.getElementById("send-form").click();
        }else{
          this.$swal({
            icon: "error",
            title: "Oopss!",
            text: "Error, usuario pendiente de validación.",
          });
        }
      }else{
        localStorage.setItem('sourceparks', 'yes');
        this.$router.push('/login_new/navbar')
      }
    },
    scrollFix: function(hash) {
      setTimeout(() => {
        const el = document.querySelector('#'+hash)
        el && el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 2000);
      // setTimeout(() => $('html, body').animate({

      // scrollTop: $('#'+hash).offset().top
      // }, 1000), 1)
    },
    goto(url){
      if(url.includes('http') || url.includes('https') ){
        console.log('web', url)
        window.open(url)
      }
      else{
        window.location.href =url
        console.log('in', url)
      }
      // console.log('goto ruta: ', rutaWeb)
      // this.$router.push(rutaWeb)
    },
    async getCategorias() {
      try {
        let res = await this.$store.dispatch("get", { path: 'categorias/getAll/'});
        console.log(res)
        if(res.length>0){
          this.itemCategoria = res.filter((x) => x.id == this.categoria_id)
          this.itemCategoria = this.itemCategoria[0]

          //this.categorias = await res
          // let categorias = res.filter((x) => x.nombre != 'Otros' && x.nombre != 'deals' && x.nombre != 'Parques')
          let categorias = res.filter((x) => x.orden_parques >0 )
          console.log('------>',res)
          for await (let it of categorias){
            for await (let el of this.card_img){
              // if(it.nombre === el.titulo){
              if(it.orden_parques === el.id){
                it.textoboton = el.textoboton
                it.columnas = el.columnas
                it.posicionpanel = el.posicionpanel
                it.columnaspanel = el.columnaspanel
                it.width = el.width
                it.height = el.height
                it.izquierda = el.izquierda
                it.alturapanel = el.alturapanel
                it.ruta = it.id == 1 ? '/cruceros' : it.id == 2 ? '/circuitos' : it.id == 4 ? '/hotel' : it.id ==5 ? '/luxury' : it.id == 6 ? '/NAO_tools' : it.id == 7 ? '/parques' : it.id == 8 ? '/otros' : it.id == 9 ? 'naodeals' : '/' //el.ruta
                it.orden = el.id
              }
            }
          }
          this.categorias = categorias.sort(function(a,b){
            return a.orden - b.orden
          } )
          console.log('categorias ordenadas---->',this.categorias)
        }
        else{
          this.categorias = []
        }
      } catch (error) {
        console.log(error)
        this.categorias = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async getCarousel() {
      try {
        let res = await this.$store.dispatch("get", { path: '/categorias_carousel/getAllByCategory/' + this.categoria_id});
        if(res.length>0){
          this.card_img2= []
          for await (let it of res){
            let data = {
              textoboton:it.texto_boton,
              columnas: 12 ,
              urlimagen : it.urlimagen,
              posicionpanel: 370,
              columnaspanel:7,
              panelhorizontal:1,
              width: '930',
              height: '370',
              izquierda: 0,
              alturapanel: 110,
              col_text: 7,
              col_button: 4,
              titulo: it.titulo,
              descripcion: it.descripcion,
              ruta: it.ruta_boton
            }
          this.card_img2.push(data)
          }
        }
        else{
          this.card_img2 = []
        }
      } catch (error) {
        this.card_img2 = []
        throw error;
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + '/getAllByCategory/' + this.categoria_id});
        console.log('********',res)
        if(res.length>0){
          for await(let it of res[0].subcategorias){
            console.log(it)
            for await(let ele of it.tarjetas){
              console.log(ele)
              // ele.ruta = '/detalle/'+ this.rutaCategoria + '/' + ele.id
              ele.ruta = ele.documento_descargable_tar!=null && ele.documento_descargable_tar!= '' ? ele.documento_descargable_tar : '/detalle/'+ this.rutaCategoria + '/' + ele.id
            }
          }
          this.items = res
        }
        else{
          this.items = []
        }
      } catch (error) {
        this.items = []
        throw error;
      }
    },

    ///////////////
    sendMessage(){
      alert('Mensaje enviado correctamente!')
    },
    goSearch() {
      localStorage.setItem("search", this.productSearch);
      this.$router.push("store/0");
    },
    gotoStore(category) {
      this.$router.push("store/" + category);
    },
    async getProducts() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.products = await this.$store.dispatch(
          "getAll",
          "newProductsByStore/" + this.storeId
        );
      } catch (error) {
        throw error;
      }
    },
    async productsOffer() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.offerProducts = await this.$store.dispatch(
          "getAll",
          "productsOffer/" + this.storeId
        );
        if(this.offerProducts){
          this.offerProducts = this.offerProducts.filter(x => x.variations.length>0);
        }
      } catch (error) {
        throw error;
      }
    },
    // showProductDetail(item) {
    //   item["quantity"] = 1;
    //   this.productData = item;
    //   this.$bvModal.show("modal-productDetail");
    // },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    showModal() {
      this.$bvModal.show("modal-chooseStore");
    },
    closeModal() {
      this.$bvModal.hide("modal-chooseStore");
      this.$bvModal.hide("modal-productDetail");
    },
  },
};
</script>
<style scoped>

.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;

  scroll-snap-type: mandatory;
  scroll-behavior: smooth;

  word-wrap: break-word !important;
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;

  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{

  height:auto !important;
  width:264px !important;

}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}


</style>
